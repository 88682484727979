import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="npc-classes"></a><h2>NPC Classes</h2>
    <h3><a id="adept"></a>ADEPT</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p className="initial"><b>Hit Die</b>: d6.</p>
    <h6>Class Skills</h6>
    <p className="initial">The adept&#8217;s class skills (and the key ability
for each skill) are <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#concentration">Concentration</a>
(Con), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#craft">Craft</a>
(Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#handle-animal">Handle Animal</a> (Cha), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#heal">Heal</a>
(Wis), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html">Knowledge</a>
(all skills taken individually) (Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#profession">Profession</a>
(Wis), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#spellcraft">Spellcraft</a> (Int), and <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#survival">Survival</a>
(Wis). </p>
    <p><b>Skill Points at 1st Level</b>: (2 + Int modifier) x 4.</p>
    <p><b>Skill Points at Each Additional Level</b>: 2 + Int modifier.</p>
    <a id="table-the-adept"></a><p><b>Table: The Adept</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th rowSpan="2" style={{
            "width": "9%"
          }}>NPC Level</th>
          <th rowSpan="2" style={{
            "width": "12%"
          }}>Base
Attack Bonus</th>
          <th rowSpan="2" style={{
            "width": "7%"
          }}>Fort
Save</th>
          <th rowSpan="2" style={{
            "width": "7%"
          }}>Ref
Save</th>
          <th rowSpan="2" style={{
            "width": "7%"
          }}>Will
Save</th>
          <th rowSpan="2" style={{
            "width": "25%"
          }}>Special</th>
          <th colSpan="6" style={{
            "textAlign": "center"
          }}>Spells per Day</th>
        </tr>
        <tr>
          <th style={{
            "width": "5%"
          }}>0</th>
          <th style={{
            "width": "5%"
          }}>1st</th>
          <th style={{
            "width": "5%"
          }}>2nd</th>
          <th style={{
            "width": "5%"
          }}>3rd</th>
          <th style={{
            "width": "5%"
          }}>4th</th>
          <th style={{
            "width": "5%"
          }}>5th</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+0</td>
          <td>+0</td>
          <td>+0</td>
          <td>+2</td>
          <td>
          </td>
          <td>3</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+1</td>
          <td>+0</td>
          <td>+0</td>
          <td>+3</td>
          <td>Summon familiar</td>
          <td>3</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+1</td>
          <td>+1</td>
          <td>+1</td>
          <td>+3</td>
          <td>
          </td>
          <td>3</td>
          <td>2</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+2</td>
          <td>+1</td>
          <td>+1</td>
          <td>+4</td>
          <td>
          </td>
          <td>3</td>
          <td>2</td>
          <td>0</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+2</td>
          <td>+1</td>
          <td>+1</td>
          <td>+4</td>
          <td>
          </td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+3</td>
          <td>+2</td>
          <td>+2</td>
          <td>+5</td>
          <td>
          </td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+3</td>
          <td>+2</td>
          <td>+2</td>
          <td>+5</td>
          <td>
          </td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+4</td>
          <td>+2</td>
          <td>+2</td>
          <td>+6</td>
          <td>
          </td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>0</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+4</td>
          <td>+3</td>
          <td>+3</td>
          <td>+6</td>
          <td>
          </td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>10th</td>
          <td>+5</td>
          <td>+3</td>
          <td>+3</td>
          <td>+7</td>
          <td>
          </td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td>+5</td>
          <td>+3</td>
          <td>+3</td>
          <td>+7</td>
          <td>
          </td>
          <td>3</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>12th</td>
          <td>+6/+1</td>
          <td>+4</td>
          <td>+4</td>
          <td>+8</td>
          <td>
          </td>
          <td>3</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>0</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td>+6/+1</td>
          <td>+4</td>
          <td>+4</td>
          <td>+8</td>
          <td>
          </td>
          <td>3</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>14th</td>
          <td>+7/+2</td>
          <td>+4</td>
          <td>+4</td>
          <td>+9</td>
          <td>
          </td>
          <td>3</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td>+7/+2</td>
          <td>+5</td>
          <td>+5</td>
          <td>+9</td>
          <td>
          </td>
          <td>3</td>
          <td>3</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>16th</td>
          <td>+8/+3</td>
          <td>+5</td>
          <td>+5</td>
          <td>+10</td>
          <td>
          </td>
          <td>3</td>
          <td>3</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>0</td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td>+8/+3</td>
          <td>+5</td>
          <td>+5</td>
          <td>+10</td>
          <td>
          </td>
          <td>3</td>
          <td>3</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
        </tr>
        <tr>
          <td>18th</td>
          <td>+9/+4</td>
          <td>+6</td>
          <td>+6</td>
          <td>+11</td>
          <td>
          </td>
          <td>3</td>
          <td>3</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>1</td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td>+9/+4</td>
          <td>+6</td>
          <td>+6</td>
          <td>+11</td>
          <td>
          </td>
          <td>3</td>
          <td>3</td>
          <td>3</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row">+10/+5</td>
          <td className="last-row">+6</td>
          <td className="last-row">+6</td>
          <td className="last-row">+12</td>
          <td className="last-row">&nbsp;</td>
          <td className="last-row">3</td>
          <td className="last-row">3</td>
          <td className="last-row">3</td>
          <td className="last-row">3</td>
          <td className="last-row">3</td>
          <td className="last-row">2</td>
        </tr>
      </tbody>
    </table>
    <h6>Class Features</h6>
    <p className="initial">All of the following are class features of the
adept NPC class.</p>
    <p><b>Weapon and Armor Proficiency</b>: Adepts are skilled with
all simple weapons. Adepts are not proficient with any type of armor
nor with shields.</p>
    <p><b>Spells</b>: An adept casts divine spells which are drawn
from the adept spell list (see below). Like a cleric, an adept must
choose and prepare her spells in advance. Unlike a cleric, an adept
cannot spontaneously cast cure or inflict spells.</p>
    <p>To prepare or cast a spell, an adept must have a Wisdom score
equal to at least 10 + the spell level. The Difficulty Class for a
saving throw against an adept&#8217;s spell is 10 + the spell level + the
adept&#8217;s Wisdom modifier.</p>
    <p>Adepts, unlike wizards, do not acquire their spells from books
or scrolls, nor do they prepare them through study. Instead, they
meditate or pray for their spells, receiving them as divine inspiration
or through their own strength of faith. Each adept must choose a time
each day at which she must spend an hour in quiet contemplation or
supplication to regain her daily allotment of spells. Time spent
resting has no effect on whether an adept can prepare spells.</p>
    <p>Like other spellcasters, an adept can cast only a certain
number of spells of each spell level per day. Her base daily spell
allotment is given on Table: The Adept. In addition, she receives bonus
spells per day if she has a high Wisdom score.</p>
    <p>When Table: The Adept indicates that the adept gets 0 spells
per day of a given spell level, she gains only the bonus spells she
would be entitled to based on her Wisdom score for that spell level.</p>
    <p>Each adept has a particular holy symbol (as a divine focus)
depending on the adept&#8217;s magical tradition.</p>
    <p><b>Summon Familiar</b>: At 2nd level, an adept can call a
familiar, just as a sorcerer or wizard can.</p>
    <a id="adept-spell-list"></a><h6>Adept Spell List</h6>
    <p className="initial">Adepts choose their spells from the following
list.</p>
    <p>0 Level: create water, cure minor wounds, detect magic, ghost
sound, guidance, light, mending, purify food and drink, read magic,
touch of fatigue.</p>
    <p>1st Level: bless, burning hands, cause fear, command,
comprehend languages, cure light wounds, detect chaos, detect evil,
detect good, detect law, endure elements, obscuring mist, protection
from chaos, protection from evil, protection from good, protection from
law, sleep.</p>
    <p>2nd Level: aid, animal trance, bear&#8217;s endurance, bull&#8217;s
strength, cat&#8217;s grace, cure moderate wounds, darkness, delay poison,
invisibility, mirror image, resist energy, scorching ray, see
invisibility, web.</p>
    <p>3rd Level: animate dead, bestow curse, contagion, continual
flame, cure serious wounds, daylight, deeper darkness, lightning bolt,
neutralize poison, remove curse, remove disease, tongues.</p>
    <p>4th Level: cure critical wounds, minor creation, polymorph,
restoration, stoneskin, wall of fire.</p>
    <p>5th Level: baleful polymorph, break enchantment, commune,
heal, major creation, raise dead, true seeing, wall of stone.</p>
    <h3><a id="aristocrat"></a>ARISTOCRAT</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p className="initial"><b>Hit Die</b>: d8.</p>
    <h6>Class Skills</h6>
    <p className="initial">The aristocrat&#8217;s class skills (and the key
ability for each skill) are <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#appraise">Appraise</a>
(Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#bluff">Bluff</a>
(Cha), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#diplomacy">Diplomacy</a> (Cha), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#disguise">Disguise</a>
(Cha), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#forgery">Forgery</a> (Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#gather-information">Gather Information</a> (Cha),
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#handle-animal">Handle Animal</a> (Cha), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#intimidate">Intimidate</a>
(Cha), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#knowledge">Knowledge</a> (all skills taken
individually) (Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#listen">Listen</a> (Wis), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#perform">Perform</a>
(Cha), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#ride">Ride</a>
(Dex), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#sense-motive">Sense Motive</a> (Wis), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#speak-language">Speak
Language</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#spot">Spot</a> (Wis), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#swim">Swim</a>
(Str), and <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#survival">Survival</a> (Wis). </p>
    <p><b>Skill Points at 1st Level</b>: (4 + Int modifier) x 4.</p>
    <p><b>Skill Points at Each Additional Level</b>: 4 + Int modifier.</p>
    <a id="table-the-aristocrat"></a><p><b>Table: The Aristocrat</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "50%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "22%"
          }}>NPC Level</th>
          <th style={{
            "width": "30%"
          }}>Base
Attack Bonus</th>
          <th style={{
            "width": "16%"
          }}>Fort
Save</th>
          <th style={{
            "width": "16%"
          }}>Ref
Save</th>
          <th style={{
            "width": "16%"
          }}>Will
Save</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+0</td>
          <td>+0</td>
          <td>+0</td>
          <td>+2</td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+1</td>
          <td>+0</td>
          <td>+0</td>
          <td>+3</td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+2</td>
          <td>+1</td>
          <td>+1</td>
          <td>+3</td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+3</td>
          <td>+1</td>
          <td>+1</td>
          <td>+4</td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+3</td>
          <td>+1</td>
          <td>+1</td>
          <td>+4</td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+4</td>
          <td>+2</td>
          <td>+2</td>
          <td>+5</td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+5</td>
          <td>+2</td>
          <td>+2</td>
          <td>+5</td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+6/+1</td>
          <td>+2</td>
          <td>+2</td>
          <td>+6</td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+6/+1</td>
          <td>+3</td>
          <td>+3</td>
          <td>+6</td>
        </tr>
        <tr>
          <td>10th</td>
          <td>+7/+2</td>
          <td>+3</td>
          <td>+3</td>
          <td>+7</td>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td>+8/+3</td>
          <td>+3</td>
          <td>+3</td>
          <td>+7</td>
        </tr>
        <tr>
          <td>12th</td>
          <td>+9/+4</td>
          <td>+4</td>
          <td>+4</td>
          <td>+8</td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td>+9/+4</td>
          <td>+4</td>
          <td>+4</td>
          <td>+8</td>
        </tr>
        <tr>
          <td>14th</td>
          <td>+10/+5</td>
          <td>+4</td>
          <td>+4</td>
          <td>+9</td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td>+11/+6/+1</td>
          <td>+5</td>
          <td>+5</td>
          <td>+9</td>
        </tr>
        <tr>
          <td>16th</td>
          <td>+12/+7/+2</td>
          <td>+5</td>
          <td>+5</td>
          <td>+10</td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td>+12/+7/+2</td>
          <td>+5</td>
          <td>+5</td>
          <td>+10</td>
        </tr>
        <tr>
          <td>18th</td>
          <td>+13/+8/+3</td>
          <td>+6</td>
          <td>+6</td>
          <td>+11</td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td>+14/+9/+4</td>
          <td>+6</td>
          <td>+6</td>
          <td>+11</td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row">+15/+10/+5</td>
          <td className="last-row">+6</td>
          <td className="last-row">+6</td>
          <td className="last-row">+12</td>
        </tr>
      </tbody>
    </table>
    <h6>Class Features</h6>
    <p className="initial">The following is a class feature of the
aristocrat NPC class.</p>
    <p><b>Weapon and Armor Proficiency</b>: The aristocrat is
proficient in the use of all simple and martial weapons and with all
types of armor and shields.</p>
    <h3><a id="commoner"></a>COMMONER</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p className="initial"><b>Hit Die</b>: d4.</p>
    <h6>Class Skills</h6>
    <p className="initial">The commoner&#8217;s class skills (and the key
ability for each skill) are <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#climb">Climb</a>
(Str), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#craft">Craft</a>
(Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#handle-animal">Handle Animal</a> (Cha), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#jump">Jump</a>
(Str), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#listen">Listen</a> (Wis), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#profession">Profession</a>
(Wis), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#ride">Ride</a>
(Dex), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#spot">Spot</a>
(Wis), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#swim">Swim</a>
(Str), and <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#use-rope">Use Rope</a> (Dex).</p>
    <p><b>Skill Points at 1st Level</b>: (2 + Int modifier) x 4.</p>
    <p><b>Skill Points at Each Additional Level</b>: 2 + Int modifier.</p>
    <a id="table-the-commoner"></a><p><b>Table: The Commoner</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "50%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "22%"
          }}>NPC Level</th>
          <th style={{
            "width": "30%"
          }}>Base
Attack Bonus</th>
          <th style={{
            "width": "16%"
          }}>Fort
Save</th>
          <th style={{
            "width": "16%"
          }}>Ref
Save</th>
          <th style={{
            "width": "16%"
          }}>Will
Save</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+0</td>
          <td>+0</td>
          <td>+0</td>
          <td>+0</td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+1</td>
          <td>+0</td>
          <td>+0</td>
          <td>+0</td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+1</td>
          <td>+1</td>
          <td>+1</td>
          <td>+1</td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+2</td>
          <td>+1</td>
          <td>+1</td>
          <td>+1</td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+2</td>
          <td>+1</td>
          <td>+1</td>
          <td>+1</td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+3</td>
          <td>+2</td>
          <td>+2</td>
          <td>+2</td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+3</td>
          <td>+2</td>
          <td>+2</td>
          <td>+2</td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+4</td>
          <td>+2</td>
          <td>+2</td>
          <td>+2</td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+4</td>
          <td>+3</td>
          <td>+3</td>
          <td>+3</td>
        </tr>
        <tr>
          <td>10th</td>
          <td>+5</td>
          <td>+3</td>
          <td>+3</td>
          <td>+3</td>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td>+5</td>
          <td>+3</td>
          <td>+3</td>
          <td>+3</td>
        </tr>
        <tr>
          <td>12th</td>
          <td>+6/+1</td>
          <td>+4</td>
          <td>+4</td>
          <td>+4</td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td>+6/+1</td>
          <td>+4</td>
          <td>+4</td>
          <td>+4</td>
        </tr>
        <tr>
          <td>14th</td>
          <td>+7/+2</td>
          <td>+4</td>
          <td>+4</td>
          <td>+4</td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td>+7/+2</td>
          <td>+5</td>
          <td>+5</td>
          <td>+5</td>
        </tr>
        <tr>
          <td>16th</td>
          <td>+8/+3</td>
          <td>+5</td>
          <td>+5</td>
          <td>+5</td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td>+8/+3</td>
          <td>+5</td>
          <td>+5</td>
          <td>+5</td>
        </tr>
        <tr>
          <td>18th</td>
          <td>+9/+4</td>
          <td>+6</td>
          <td>+6</td>
          <td>+6</td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td>+9/+4</td>
          <td>+6</td>
          <td>+6</td>
          <td>+6</td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row">+10/+5</td>
          <td className="last-row">+6</td>
          <td className="last-row">+6</td>
          <td className="last-row">+6</td>
        </tr>
      </tbody>
    </table>
    <h6>Class Features</h6>
    <p className="initial">The following is a class feature of the
commoner NPC class.</p>
    <p><b>Weapon and Armor Proficiency</b>: The commoner is
proficient with one simple weapon. He is not proficient with any other
weapons, nor is he proficient with any type of armor or shields.</p>
    <h3><a id="expert"></a>EXPERT</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p className="initial"><b>Hit Die</b>: d6.</p>
    <h6>Class Skills</h6>
    <p className="initial">The expert can choose any ten skills to be
class skills.</p>
    <p><b>Skill Points at 1st Level</b>: (6 + Int modifier) x 4.</p>
    <p><b>Skill Points at Each Additional Level</b>: 6 + Int modifier.</p>
    <a id="table-the-expert"></a><p><b>Table: The Expert</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "50%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "22%"
          }}>NPC Level</th>
          <th style={{
            "width": "30%"
          }}>Base
Attack Bonus</th>
          <th style={{
            "width": "16%"
          }}>Fort
Save</th>
          <th style={{
            "width": "16%"
          }}>Ref
Save</th>
          <th style={{
            "width": "16%"
          }}>Will
Save</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+0</td>
          <td>+0</td>
          <td>+0</td>
          <td>+2</td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+1</td>
          <td>+0</td>
          <td>+0</td>
          <td>+3</td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+2</td>
          <td>+1</td>
          <td>+1</td>
          <td>+3</td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+3</td>
          <td>+1</td>
          <td>+1</td>
          <td>+4</td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+3</td>
          <td>+1</td>
          <td>+1</td>
          <td>+4</td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+4</td>
          <td>+2</td>
          <td>+2</td>
          <td>+5</td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+5</td>
          <td>+2</td>
          <td>+2</td>
          <td>+5</td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+6/+1</td>
          <td>+2</td>
          <td>+2</td>
          <td>+6</td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+6/+1</td>
          <td>+3</td>
          <td>+3</td>
          <td>+6</td>
        </tr>
        <tr>
          <td>10th</td>
          <td>+7/+2</td>
          <td>+3</td>
          <td>+3</td>
          <td>+7</td>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td>+8/+3</td>
          <td>+3</td>
          <td>+3</td>
          <td>+7</td>
        </tr>
        <tr>
          <td>12th</td>
          <td>+9/+4</td>
          <td>+4</td>
          <td>+4</td>
          <td>+8</td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td>+9/+4</td>
          <td>+4</td>
          <td>+4</td>
          <td>+8</td>
        </tr>
        <tr>
          <td>14th</td>
          <td>+10/+5</td>
          <td>+4</td>
          <td>+4</td>
          <td>+9</td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td>+11/+6/+1</td>
          <td>+5</td>
          <td>+5</td>
          <td>+9</td>
        </tr>
        <tr>
          <td>16th</td>
          <td>+12/+7/+2</td>
          <td>+5</td>
          <td>+5</td>
          <td>+10</td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td>+12/+7/+2</td>
          <td>+5</td>
          <td>+5</td>
          <td>+10</td>
        </tr>
        <tr>
          <td>18th</td>
          <td>+13/+8/+3</td>
          <td>+6</td>
          <td>+6</td>
          <td>+11</td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td>+14/+9/+4</td>
          <td>+6</td>
          <td>+6</td>
          <td>+11</td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row">+15/+10/+5</td>
          <td className="last-row">+6</td>
          <td className="last-row">+6</td>
          <td className="last-row">+12</td>
        </tr>
      </tbody>
    </table>
    <h6>Class Features</h6>
    <p className="initial">The following is a class feature of the expert
NPC class.</p>
    <p><b>Weapon and Armor Proficiency</b>: The expert is proficient
in the use of all simple weapons and with light armor but not shields. </p>
    <h3><a id="warrior"></a>WARRIOR</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p className="initial"><b>Hit Die</b>: d8.</p>
    <h6>Class Skills</h6>
    <p className="initial">The warrior&#8217;s class skills (and the key
ability for each skill) are <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#climb">Climb</a>
(Str), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#handle-animal">Handle Animal</a> (Cha), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#intimidate">Intimidate</a>
(Cha), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#jump">Jump</a>
(Str), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#ride">Ride</a>
(Dex), and <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#swim">Swim</a> (Str).</p>
    <p><b>Skill Points at 1st Level</b>: (2 + Int modifier) x 4.</p>
    <p><b>Skill Points at Each Additional Level</b>: 2 + Int modifier.</p>
    <a id="table-the-warrior"></a><p><b>Table: The Warrior</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "50%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "22%"
          }}>NPC Level</th>
          <th style={{
            "width": "30%"
          }}>Base
Attack Bonus</th>
          <th style={{
            "width": "16%"
          }}>Fort
Save</th>
          <th style={{
            "width": "16%"
          }}>Ref
Save</th>
          <th style={{
            "width": "16%"
          }}>Will
Save</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+1</td>
          <td>+2</td>
          <td>+0</td>
          <td>+0</td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+2</td>
          <td>+3</td>
          <td>+0</td>
          <td>+0</td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+3</td>
          <td>+3</td>
          <td>+1</td>
          <td>+1</td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+4</td>
          <td>+4</td>
          <td>+1</td>
          <td>+1</td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+5</td>
          <td>+4</td>
          <td>+1</td>
          <td>+1</td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+6/+1</td>
          <td>+5</td>
          <td>+2</td>
          <td>+2</td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+7/+2</td>
          <td>+5</td>
          <td>+2</td>
          <td>+2</td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+8/+3</td>
          <td>+6</td>
          <td>+2</td>
          <td>+2</td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+9/+4</td>
          <td>+6</td>
          <td>+3</td>
          <td>+3</td>
        </tr>
        <tr>
          <td>10th</td>
          <td>+10/+5</td>
          <td>+7</td>
          <td>+3</td>
          <td>+3</td>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td>+11/+6/+1</td>
          <td>+7</td>
          <td>+3</td>
          <td>+3</td>
        </tr>
        <tr>
          <td>12th</td>
          <td>+12/+7/+2</td>
          <td>+8</td>
          <td>+4</td>
          <td>+4</td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td>+13/+8/+3</td>
          <td>+8</td>
          <td>+4</td>
          <td>+4</td>
        </tr>
        <tr>
          <td>14th</td>
          <td>+14/+9/+4</td>
          <td>+9</td>
          <td>+4</td>
          <td>+4</td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td>+15/+10/+5</td>
          <td>+9</td>
          <td>+5</td>
          <td>+5</td>
        </tr>
        <tr>
          <td>16th</td>
          <td>+16/+11/+6/+1</td>
          <td>+10</td>
          <td>+5</td>
          <td>+5</td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td>+17/+12/+7/+2</td>
          <td>+10</td>
          <td>+5</td>
          <td>+5</td>
        </tr>
        <tr>
          <td>18th</td>
          <td>+18/+13/+8/+3</td>
          <td>+11</td>
          <td>+6</td>
          <td>+6</td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td>+19/+14/+9/+4</td>
          <td>+11</td>
          <td>+6</td>
          <td>+6</td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row">+20/+15/+10/+5</td>
          <td className="last-row">+12</td>
          <td className="last-row">+6</td>
          <td className="last-row">+6</td>
        </tr>
      </tbody>
    </table>
    <h6>Class Features</h6>
    <p className="initial">The following is a class feature of the
warrior NPC class.</p>
    <p><b>Weapon and Armor Proficiency</b>: The warrior is proficient
in the use of all simple and martial weapons and all armor and shields.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      